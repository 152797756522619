import { createRoot } from 'react-dom/client';
import { lazy, Suspense } from 'react';
import './App.scss';
import "@strana/style-vars/ui.css";
import "@strana/react-ui-kit/dist/style.css";


const App = lazy(() => import('./App'));

createRoot(document.getElementById('root')!).render(
  <Suspense>
    <App />
  </Suspense>
);
